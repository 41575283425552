import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    emailStaticTemplateList: [],
    selected: "",
    searchText: "",
    selectedId: 0,
    selectedRows: [],
    groupList: [],
    selectedGroup: "",
    type: "internal",
    selectedItem: null,
    showLoader: false,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),

  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.emailStaticTemplateList.length > 0) {
        return (
          _.where(this.emailStaticTemplateList, { isSelected: true }).length ===
          this.emailStaticTemplateList.length
        );
      }
      return false;
    },
    hasemailStaticTempleShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailStaticTemplate-index";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailStaticTempletUpdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailStaticTemplate-update";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    formatString(input) {
      // Split the string by underscore, capitalize each word, and join them back with a space
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    getGroup() {
      let _vm = this;
      this.axios
        .get("/email-static-templates-groups")
        .then(function (response) {
          _vm.groupList = response.data.data;
        })
        .catch(function () { });
    },
    checkUncheckedList(event) {
      this.emailStaticTemplateList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    openModal(emailStaticTemplateList) {
      this.selectedItem = emailStaticTemplateList;
    },
    closeModal() {
      this.selectedItem = null;
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&type=" +
        this.type;
      queryString +=
        this.selectedGroup !== "" ? "&group=" + this.selectedGroup : "";
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getDataAll(clientQuery) {
      if (clientQuery) {
        this.type = !this.type;
      }
      this.getAll();
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/email-static-templates" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.emailStaticTemplateList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () { });
    },
  },
  mounted() {
    this.getAll();
    this.getGroup();
  },
};
